<template>
  <CChartHorizontalBar
    :datasets="defaultDatasets"
    labels="['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']"
  />
</template>

<script>
import { CChartHorizontalBar } from '@coreui/vue-chartjs'

export default {
  name: 'CChartHBar',
  components: { CChartHorizontalBar },
  computed: {
    defaultDatasets () {
      return [
        {
          label: 'GitHub Commits',
          backgroundColor: '#f87979',
          data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
        }
      ]
    }
  }
}
</script>
